<template>
  <HelloWorld/>
  <TaskGraph/>
</template>

<script>
import TaskGraph from "@/components/TaskGraph";
import HelloWorld from "@/components/HelloWorld";
export default {
  components: {TaskGraph, HelloWorld},

}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.chatbot {
  margin-top: 20px;
}

.chat-log {
  max-height: 300px;
  overflow-y: scroll;
  background-color: #F4F4F4; /* Gris clair */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user {
  background-color: #E5003B; /* Rose vif */
  align-self: flex-end;
  color: white; /* Texte en blanc pour le contraste */
}

.bot {
  background-color: #00A2E8; /* Bleu clair */
  align-self: flex-start;
  color: white; /* Texte en blanc pour le contraste */
}

.input-area {
  display: flex;
  justify-content: space-between;
}

input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Bordure gris clair */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #E5003B; /* Rose vif */
  color: white; /* Texte en blanc pour le contraste */
  cursor: pointer;
}

</style>
