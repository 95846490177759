<template>
  <div>
    <h2>Nombre de TK par jour</h2>
    <p>Moyenne des TK par semaine : {{ calculateAverage().toFixed(2) }}</p>
    <div>
      <input
          type="number"
          v-model.number="taskInputs[0]"
          placeholder="Tâches - Jour 1"
      />
      <input
          type="number"
          v-model.number="taskInputs[1]"
          placeholder="Tâches - Jour 2"
      />
      <input
          type="number"
          v-model.number="taskInputs[2]"
          placeholder="Tâches - Jour 3"
      />
      <input
          type="number"
          v-model.number="taskInputs[3]"
          placeholder="Tâches - Jour 4"
      />
      <input
          type="number"
          v-model.number="taskInputs[4]"
          placeholder="Tâches - Jour 5"
      />
      <button @click="updateTasks">Mettre à jour</button>
    </div>

    <!-- Afficher le graphique uniquement si les données sont disponibles -->
    <bar-chart v-if="chartData.datasets.length" :chart-data="chartData" />

  </div>
</template>

<script>
import BarChart from './BarChart.vue';

export default {
  name: 'TaskGraph',
  components: {
    BarChart
  },
  data() {
    return {
      // Un tableau pour stocker les valeurs des 5 champs d'entrée
      taskInputs: [null, null, null, null, null], // Initialiser avec null ou 0
      tasks: [], // Liste des tâches effectuées (maximum 7 jours)
    };
  },
  computed: {
    chartData() {
      const data = this.tasks.length > 0 ? this.tasks : Array(7).fill(0);
      return {
        labels: ['Jour 1', 'Jour 2', 'Jour 3', 'Jour 4', 'Jour 5', 'Jour 6', 'Jour 7'],
        datasets: [
          {
            label: 'Tâches effectuées',
            backgroundColor: '#42A5F5',
            data: data.slice(0, 7), // Assurez-vous que nous avons exactement 7 jours
          },
        ],
      };
    },
  },
  methods: {
    updateTasks() {
      // Réinitialiser la liste des tâches
      this.tasks = [];

      // Ajout des valeurs des champs d'entrée à la liste des tâches
      this.taskInputs.forEach((input) => {
        if (input !== null && input !== '') { // Vérifier que l'entrée n'est pas nulle ou vide
          this.tasks.push(input);
        }
      });

      // Limiter le tableau à 7 jours (si vous avez besoin de garder un maximum de 7 valeurs)
      if (this.tasks.length > 7) {
        this.tasks = this.tasks.slice(-7); // Garder seulement les dernières 7 tâches
      }

      // Réinitialiser les champs d'entrée
      this.taskInputs.fill(null); // Remplir avec null ou 0 pour réinitialiser
    },
    calculateAverage() {
      return this.tasks.length > 0 ? this.tasks.reduce((a, b) => a + b, 0) / this.tasks.length : 0;
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}
input {
  margin-right: 10px;
  margin-bottom: 10px; /* Ajout d'un espacement vertical entre les inputs */
}
</style>
