<template>
  <div class="container">
    <h1>Super ACM</h1>
    <div class="chatbot" v-if="pdfLoaded">
      <div class="chat-log">
        <div
            v-for="(message, index) in chatLog"
            :key="index"
            class="message"
            :class="{ user: message.isUser, bot: !message.isUser }"
        >
          {{ message.text }}
        </div>
      </div>
      <div class="input-area">
        <input v-model="userInput" @keyup.enter="sendMessage" placeholder="Posez une question..." />
        <button @click="sendMessage">Envoyer</button>
      </div>
    </div>
    <div v-else>
      <p>Chargement du PDF...</p>
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

// Set the worker source
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

export default {
  name: 'HelloWorld',
  data() {
    return {
      textContent: "",
      userInput: "",
      chatLog: [],
      pdfLoaded: false,
    };
  },
  mounted() {
    this.loadPdf(); // Charger le PDF au montage
  },
  methods: {
    async loadPdf() {
      const url = "../pdf/CGV.pdf"; // Chemin du PDF dans le répertoire public
      const pdf = await pdfjsLib.getDocument(url).promise;
      let textContent = "";

      // Commencer à partir de la page 3
      for (let i = 3; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const text = await page.getTextContent();
        textContent += text.items.map((item) => item.str).join(" ") + " ";
      }

      this.textContent = textContent.toLowerCase(); // Convertir en minuscule pour la recherche
      this.pdfLoaded = true; // Indiquer que le PDF a été chargé
    },
    sendMessage() {
      if (this.userInput.trim() === "") {
        return; // Ne rien faire si l'entrée est vide
      }

      const userMessage = { text: this.userInput, isUser: true };
      this.chatLog.push(userMessage);

      const botResponse = this.generateResponse(this.userInput);
      const botMessage = { text: botResponse, isUser: false };
      this.chatLog.push(botMessage);

      this.userInput = ""; // Réinitialiser l'entrée de l'utilisateur
    },
    generateResponse(question) {
      const questionLower = question.toLowerCase();

      // Vérifier si une partie du texte correspond à la question
      if (this.textContent.includes(questionLower)) {
        const foundIndex = this.textContent.indexOf(questionLower);
        const snippet = this.textContent.slice(foundIndex, foundIndex + 1000); // Extraire un extrait de 1000 caractères
        return `Voici un extrait pertinent : ${snippet}...`;
      }

      // Si aucune correspondance n'est trouvée
      return "Je ne trouve pas de réponse dans le document.";
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.chatbot {
  margin-top: 20px;
}

.chat-log {
  max-height: 300px;
  overflow-y: scroll;
  background-color: #f4f4f4; /* Gris clair */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user {
  background-color: #e5003b; /* Rose vif */
  align-self: flex-end;
  color: white; /* Texte en blanc pour le contraste */
}

.bot {
  background-color: #00a2e8; /* Bleu clair */
  align-self: flex-start;
  color: white; /* Texte en blanc pour le contraste */
}

.input-area {
  display: flex;
  justify-content: space-between;
}

input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Bordure gris clair */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #e5003b; /* Rose vif */
  color: white; /* Texte en blanc pour le contraste */
  cursor: pointer;
}
</style>
